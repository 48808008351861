import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@signature/webfrontauth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { RouterHistoryService } from 'src/app/core/services/routerHistoryService/router-history.service';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { dateToLocalFR } from 'src/app/shared/date-helper';
import { UserService } from 'src/app/shared/services/user.service';
import { PostFormComponent } from '../../components/post-form/post-form.component';
import { SendMailFormComponent } from '../../components/send-mail-form/send-mail-form.component';
import { CreatePostCommand } from '../../shared/commands/post/create-post.command';
import { EditPostCommand } from '../../shared/commands/post/edit-post.command';
import { SendPostByMailCommand } from '../../shared/commands/post/send-post-by-mail.command';
import { SendReportByMailCommand } from '../../shared/commands/post/send-report-by-mail.command';
import { EditProjectStatusCommand } from '../../shared/commands/project/edit-project-status.command';
import { ACTION_STATUS } from '../../shared/models/posts.enum';
import { PROJECT_STATUS } from '../../shared/models/project-status.model';
import { Action, ActionStatus, Post, ProjectData, ProjectStatus, ProjectWithPosts } from '../../shared/models/project.model';

@Component( {
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.less']
} )
export class WorksheetComponent implements OnInit, OnDestroy {
  @ViewChild( 'modalComponent' ) modalComponent: GenericModalComponent;

  private _projectId: number;
  private _subs: Array<Subscription>;
  public projectData?: ProjectWithPosts;
  public projectStatus = PROJECT_STATUS;
  public actionStatus = ACTION_STATUS;
  public allStatus: Array<ProjectStatus>;
  public period: Date[];
  public allActionStatus: Array<ActionStatus>;
  public selectedProjectStatus: number;
  public selectedActionStatus: number;
  public editingProjectStatus: boolean;
  public selectedPost?: Post;
  public showPostModal: boolean;
  public privateMode: boolean;

  public displayedInfos: Array<Post>;
  public displayedDecisions: Array<Post>;
  public displayedActions: Array<Action>;

  constructor (
    private _projectApiService: ProjectApiService,
    private _routerHistoryService: RouterHistoryService,
    private _formBuilder: FormBuilder,
    private _crsService: CrsService,
    private _nzMessageService: NzMessageService,
    private _authService: AuthService,
    private _router: Router,
    private _userService: UserService
  ) {
    this._subs = [];
    this.period = [];
    const midnight = new Date();
    midnight.setUTCHours( 0, 0, 0, 0 );
    this.period.push( midnight );
    const tonight = new Date();
    tonight.setUTCHours( 21, 59, 59, 59 );
    this.period.push( tonight );

    this.editingProjectStatus = false;
    this.showPostModal = false;
    this.privateMode = false;
  }

  ngOnDestroy(): void {
    this._subs.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this._subs.push(
      this._routerHistoryService.currentUrl$.pipe(
        switchMap( ( url: string ): Observable<ProjectWithPosts> => {
          const projectId = parseInt( url.split( '/projects/' )[1] );
          this._projectId = projectId;
          return this.getProjectDataSubscription( projectId );
        } ),
        switchMap( ( projectData => {
          this.projectData = { ...projectData };

          return this._projectApiService.getProjectStatus().pipe( first() );
        } ) ),
        switchMap( ( s => {
          this.allStatus = [...s];
          this.selectedProjectStatus = s.find( s => s.projectStatusId === this.projectData.projectStatusId ).projectStatusId;

          return this.getActionStatus();
        } ) )
      ).subscribe( s => {
        this.allActionStatus = [...s];
      } )
    );

    this._subs.push(
      this._userService.privateMode$.subscribe( privateMode => {
        this.privateMode = privateMode;
        this.mapPosts();
      } )
    );

    this.onPeriodChange( this.period );
  }

  getProjectDataSubscription( projectId: number ): Observable<ProjectWithPosts> {
    return this._projectApiService.getProjectWithPosts( projectId, this.period[0], this.period[1] );
  }

  getActionStatus(): Observable<Array<ActionStatus>> {
    return this._projectApiService.getAllActionStatus().pipe( first() );
  }

  onPeriodChange( result: Date[] ): void {
    if ( result[0].getTime() === result[1].getTime() ) {
      result[1].setUTCHours( 21, 59, 59, 59 );
    }
    this.period = [...result];
    this.getProjectData( this._projectId );
  }

  editProjectStatus(): void {
    const successMsg = `La modification s'est déroulée avec succès.`;
    const errorMsg = `Une erreur est survenue lors de la modification.`;

    const command = new EditProjectStatusCommand(
      this._authService.authenticationInfo.user.userId,
      this._projectId,
      this.selectedProjectStatus
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.getProjectData( this._projectId );
      this.editingProjectStatus = false;
    } );
  }

  disabledEndDate = ( endValue: Date ): boolean => {
    const today = new Date()
    const tomorrow = new Date( today )
    tomorrow.setDate( tomorrow.getDate() + 1 )

    if ( !endValue ) {
      return false;
    }
    return endValue.getTime() > tomorrow.getTime();
  }

  formatDate( date: Date ): string {
    return dateToLocalFR( date.toString() );
  }

  createPostFormGroup(): FormGroup {
    return this._formBuilder.group( {
      postType: [0, [Validators.required, Validators.min( 1 )]],
      postContent: ['', [Validators.required, Validators.minLength( 1 ), Validators.maxLength( 255 )]],
      description: [''],
      isImportant: [false],
      isPrivate: [false],
      openedDate: [new Date(), Validators.required],
      assignedUsers: [[], Validators.required],
      deadLine: [null]
    } );
  }

  openCreatePostModal( type?: string ): void {
    const title = `Nouveau post`;
    const form: FormGroup = this.createPostFormGroup();
    const componentParams = { postForm: form, selectedType: type ?? type, projectId: this.projectData?.projectId };
    this.modalComponent.openFormModal( title, PostFormComponent, componentParams, 'createPost' );
  }

  editPostFormGroup( post: Post ): FormGroup {
    return this._formBuilder.group( {
      postId: [post.postId],
      postType: [post.postTypeId, Validators.required],
      postContent: [post.title, Validators.required],
      isImportant: [post.isImportant, Validators.required]
    } );
  }

  openEditPostModal( post: Post ): void {
    const title = `Modification du post #${post.postId}`;
    const form: FormGroup = this.editPostFormGroup( post );
    const componentParams = { postForm: form };
    this.modalComponent.openFormModal( title, PostFormComponent, componentParams, 'editPost' );
  }

  modalFormValidated( result: { form: FormGroup, action: string } ): void {
    var messages = this.computeMessages( result.action );

    const command = this.createCrsCommand( result.form, result.action );
    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( messages.success );
      } else {
        this._nzMessageService.error( messages.error );
      }

      this.getProjectData( this._projectId );
    } );
  }

  computeMessages( commandName: string ) {
    let successMsg = '';
    let errorMsg = '';

    switch ( commandName ) {
      case 'createPost':
        successMsg = 'La création s\'est déroulée avec succès.';
        errorMsg = `Une erreur est survenue lors de la création.`;
        break;
      case 'editPost':
        successMsg = 'L\'édition s\'est déroulée avec succès.';
        errorMsg = `Une erreur est survenue lors de l'édition.`;
        break;
      case 'sendPostByMail':
        successMsg = 'Le post a été envoyé avec succès.';
        errorMsg = `Une erreur est survenue lors de l'envoi par e-mail.`;
        break;
      case 'sendReportByMail':
        successMsg = 'Un rapport a été généré et envoyé par e-mail.';
        errorMsg = `Une erreur est survenue lors de l'envoi par e-mail.`;
        break;
      default:
        break;
    }

    return { success: successMsg, error: errorMsg };
  }

  createCrsCommand( form: FormGroup, actionName: string ) {
    let command = null;
    switch ( actionName ) {
      case 'createPost':
        command = new CreatePostCommand(
          this._authService.authenticationInfo.user.userId,
          this._projectId,
          form.get( 'postType' ).value,
          form.get( 'postContent' ).value,
          form.get( 'description' ).value,
          form.get( 'isImportant' ).value,
          form.get( 'isPrivate' ).value,
          form.get( 'openedDate' ).value,
          form.get( 'assignedUsers' ).value,
          form.get( 'deadLine' ).value
        );
        break;
      case 'editPost':
        command = new EditPostCommand(
          this._authService.authenticationInfo.user.userId,
          form.get( 'postId' ).value,
          form.get( 'postContent' ).value,
          form.get( 'isImportant' ).value
        );
        break;
      case 'sendPostByMail':
        command = new SendPostByMailCommand(
          this._authService.authenticationInfo.user.userId,
          form.get( 'postId' ).value,
          form.get( 'mailContent' ).value,
          form.get( 'destination' ).value
        );
        break;
      case 'sendReportByMail':
        command = new SendReportByMailCommand(
          this._authService.authenticationInfo.user.userId,
          this._projectId,
          this.period[0].toJSON(),
          this.period[1].toJSON(),
          form.get( 'mailContent' ).value,
          form.get( 'destination' ).value,
          this.privateMode
        );
        break;
      default:
        break;
    }

    return command;
  }

  getProjectData( projectId: number ) {
    this._projectApiService.getProjectWithPosts( projectId, this.period[0], this.period[1] )
      .pipe( first() )
      .subscribe( p => {
        this.projectData = { ...p };
        this.selectedProjectStatus = p.projectStatusId;
        this.mapPosts();
      } );
  }

  onBack(): void {
    this._router.navigate( [`/projects/${this._projectId}`] );
  }

  openPostModal( post: Post ): void {
    this.selectedPost = post;
    this.showPostModal = true;
  }

  closeModal(): void {
    this.showPostModal = false;
    this.selectedPost = undefined;
  }

  openSendMailModal( post: Post ): void {
    const title = `Veuillez saisir un destinataire`;
    const form: FormGroup = this.sendMailFormGroup( post );
    const componentParams = { formGroup: form, projectId: this._projectId };
    this.modalComponent.openFormModal( title, SendMailFormComponent, componentParams, 'sendPostByMail' );
  }

  sendMailFormGroup( post: Post ): FormGroup {
    return this._formBuilder.group( {
      postId: [post.postId],
      destination: [[], Validators.required],
      mailContent: ['', Validators.required]
    } );
  }

  sendReportByMailFormGroup(): FormGroup {
    return this._formBuilder.group( {
      destination: [[], Validators.required],
      mailContent: ['', Validators.required]
    } );
  }

  sendReportByMail(): void {
    const title = `Veuillez saisir un destinataire`;
    const form: FormGroup = this.sendReportByMailFormGroup();
    const componentParams = { formGroup: form, projectId: this._projectId };
    this.modalComponent.openFormModal( title, SendMailFormComponent, componentParams, 'sendReportByMail' );
  }

  mapPosts(): void {
    if ( this.projectData ) {
      if ( this.privateMode ) {
        this.displayedActions = [...this.projectData.actions];
        this.displayedDecisions = [...this.projectData.decisions];
        this.displayedInfos = [...this.projectData.informations];
      } else {
        this.displayedActions = [...this.projectData.actions.filter( a => !a.isPrivate )];
        this.displayedDecisions = [...this.projectData.decisions.filter( d => !d.isPrivate )];
        this.displayedInfos = [...this.projectData.informations.filter( i => !i.isPrivate )];
      }
    }
  }
}
