import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@signature/webfrontauth';
import { Subscription } from 'rxjs';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';
import { UserService } from 'src/app/shared/services/user.service';
import { NotificationDrawerComponent } from '../notification-drawer/notification-drawer.component';

@Component( {
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.less']
} )
export class TopbarComponent implements OnInit, OnDestroy {
  @ViewChild( 'notifComponent' ) notifComponent: NotificationDrawerComponent;
  private _subs: Array<Subscription>;
  public userInitials: string;
  public privateMode: boolean;
  public hasNewNotifs: boolean;

  constructor (
    private _router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _hubService: NotificationHubService
  ) {
    this.userInitials = this._authService.authenticationInfo.user.userName.substring( 0, 1 );
    this.privateMode = false;
    this.hasNewNotifs = false;
    this._subs = [];
  }

  ngOnDestroy(): void {
    this._subs.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this._subs.push(
      this._hubService.hasUnreadNotifs$.subscribe( b => this.hasNewNotifs = b )
    );
  }

  setPrivateMode( bool: boolean ): void {
    this.privateMode = bool;
    this._userService.setPrivateMode( bool );
  }

  goToHome(): void {
    this._router.navigate( [''] );
  }

  async logout(): Promise<void> {
    await this._authService.logout();
    this.setPrivateMode( false );
    this._router.navigate( ['/login'] );
    this._hubService.stopConnection();
  }

  shoudShowPrivateMode(): boolean {
    return this._userService.isSignatureUser();
  }

  changePrivateMode(): void {
    this.privateMode = !this.privateMode;
    this._userService.setPrivateMode( this.privateMode );
  }

  openNotif(): void {
    this.notifComponent.openDrawer();
    this._hubService.setHasUnreadNotifs( false );
  }
}
