<nz-layout class="app-layout">
  <nz-header class="app-header">
    <app-topbar></app-topbar>
  </nz-header>

  <nz-layout class="router-layout">
    <div [ngClass]="collapsed ? 'sidebar-container collapsed' : 'sidebar-container'">
      <app-sidebar #sidebar (collapsed)="isCollapsed($event)"></app-sidebar>
    </div>

    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>