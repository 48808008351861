import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { dateToLocalFR } from 'src/app/shared/date-helper';
import { UserService } from 'src/app/shared/services/user.service';
import { ACTION_STATUS, POST_TYPES } from '../../shared/models/posts.enum';
import { Action, Post } from '../../shared/models/project.model';

@Component( {
  selector: 'app-post-display',
  templateUrl: './post-display.component.html',
  styleUrls: ['./post-display.component.less']
} )
export class PostDisplayComponent implements OnInit, OnDestroy, OnChanges {
  @Input() projectId: number;
  @Input() infos: Array<Post>;
  @Input() decisions: Array<Post>;
  @Input() actions: Array<Action>;
  @Output() dataModified = new EventEmitter<void>();

  private _subscriptions: Array<Subscription>;
  public postTypes = POST_TYPES;
  public showPostModal: boolean;
  public selectedPost?: Post;
  public actionTypes = ACTION_STATUS;
  public privateMode: boolean;

  public displayedInfos: Array<Post>;
  public displayedDecisions: Array<Post>;
  public displayedActions: Array<Action>;

  constructor (
    private _userService: UserService,
    private _location: Location
  ) {
    this._subscriptions = new Array<Subscription>();
    this.showPostModal = false;
    this.privateMode = false;
  }

  ngOnChanges( changes: SimpleChanges ): void {
    this.mapPosts();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._userService.privateMode$.subscribe( privateMode => {
        this.privateMode = privateMode;
        this.mapPosts();
      } )
    );
  }

  mapPosts(): void {
    if ( this.privateMode ) {
      this.displayedActions = [...this.actions];
      this.displayedDecisions = [...this.decisions];
      this.displayedInfos = [...this.infos];
    } else {
      this.displayedActions = [...this.actions.filter( a => !a.isPrivate )];
      this.displayedDecisions = [...this.decisions.filter( d => !d.isPrivate )];
      this.displayedInfos = [...this.infos.filter( i => !i.isPrivate )];
    }
  }

  openPostModal( post: Post ): void {
    if ( post ) {
      this.selectedPost = post;
      this._location.replaceState( `/projects/${this.projectId}/post/${post.postId}` );
      this.showPostModal = true;
    }
  }

  closeModal(): void {
    this.showPostModal = false;
    this.selectedPost = undefined;
    this._location.replaceState( `/projects/${this.projectId}` );
    this.dataModified.next();
  }

  formatDate( date: Date ): string {
    return dateToLocalFR( date.toString() );
  }
}
