import { Command } from "@signature/crs-client";

@Command( 'SetDeadLine' )
export class EditDeadLineDateCommand {
    constructor (
        public actorId: number,
        public postId: number,
        public deadline: Date
    ) {
    }
}