<div class="worksheet-container">
    <ng-container *ngIf="projectData">
        <div class="header">
            <div class="infos">
                <i class="arrow" nz-icon nzType="arrow-left" nzTheme="outline" (click)="onBack()"></i>
                <span class="project-name">{{ projectData.projectName }}</span>
                <div [ngSwitch]="projectData.projectStatusId" class="tag">
                    <ng-container *ngIf="projectData.isPrivate">
                        <nz-tag nzColor="error">
                            Privé
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="projectData.isArchived">
                        <nz-tag [nzColor]="'red'">
                            Archivé
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="!editingProjectStatus">
                        <a nz-button nzType="link" (click)="editingProjectStatus = true">
                            <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </a>
                        <ng-container *ngSwitchCase="projectStatus.PENDING">
                            <nz-tag nzColor="default">{{ projectData.projectStatus }}</nz-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="projectStatus.IN_PROGRESS">
                            <nz-tag [nzColor]="'gold'">{{ projectData.projectStatus }}</nz-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="projectStatus.COMPLETED">
                            <nz-tag [nzColor]="'#87d068'">{{ projectData.projectStatus }}</nz-tag>
                        </ng-container>                    
                    </ng-container>
                </div>
                <div class="status-change" *ngIf="allStatus && allStatus.length > 0 && editingProjectStatus">
                    <nz-select [(ngModel)]="selectedProjectStatus">
                        <ng-container *ngFor="let s of allStatus">
                            <nz-option [nzLabel]="s.status" [nzValue]="s.projectStatusId"></nz-option>
                        </ng-container>
                    </nz-select>
                    <button nz-button nzType="primary" (click)="editProjectStatus()" nz-tooltip="Valider">
                        <i nz-icon nzType="check" nzTheme="outline"></i>
                    </button>
                    <a *ngIf="editingProjectStatus" nz-button nzType="link" (click)="editingProjectStatus = false" nz-tooltip="Annuler">
                        <i nz-icon nzType="close" nzTheme="outline"></i>
                    </a>
                </div>
            </div>
            <div class="right-header">
                <button nz-button nzType="primary" (click)="sendReportByMail()">
                    <i nz-icon nzType="mail" nzTheme="outline"></i>
                    Envoyer un rapport
                </button>
                <nz-range-picker 
                    [(ngModel)]="period" 
                    (ngModelChange)="onPeriodChange($event)"
                    [nzSize]="'large'" 
                    [nzDisabledDate]="disabledEndDate" 
                    nzFormat="dd/MM/yyyy">
                </nz-range-picker>
            </div>
        </div>
    
        <div *ngIf="period && period.length > 0" class="posts-container">
            <div class="lists">
                <nz-list nzBordered nzSize="large" class="list">
                    <nz-list-header class="list-header">
                        <nz-tag [nzColor]="'volcano'">
                            <i nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
                        </nz-tag>
                        Décisions
                    </nz-list-header>
                    <nz-list-item *ngFor="let item of displayedDecisions" class="list-content" (dblclick)="openPostModal(item)">
                        <div class="post-title">
                            <div class="post">
                                <span>#{{ item.postId }} {{ item.title }}</span>
                                <div class="right-post">
                                    <ng-container *ngIf="item.isImportant">
                                        <nz-tag nzColor="error">
                                            <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip="Important"></i>
                                        </nz-tag>
                                    </ng-container>
                                    <a nz-button nzType="link" (click)="openSendMailModal( item )" nz-tooltip="Envoyer">
                                        <i nz-icon nzType="mail" nzTheme="outline"></i>
                                    </a>
                                    <a nz-button nzType="link" (click)="openEditPostModal( item )" nz-tooltip="Modifier">
                                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="post-dates">
                                <span class="owner"> - {{ item.ownerUserName }} le {{ formatDate( item.creationDate ) }}</span>
                                <span class="owner" *ngIf="formatDate(item.lastEditionDate) !== '-'">Modifié le : {{ formatDate( item.lastEditionDate ) }}</span>
                            </div>
                        </div>
                    </nz-list-item>
                    <nz-list-footer class="list-footer">
                        <a class="add-button" nz-button nzType="link" (click)="openCreatePostModal( 'Décision' )">
                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                            Ajouter une décision
                        </a>
                    </nz-list-footer>
                </nz-list>
            </div>
            <div class="lists">
                <nz-list nzBordered nzSize="large" class="list">
                    <nz-list-header class="list-header">
                        <nz-tag [nzColor]="'blue'">
                            <i nz-icon nzType="issues-close" nzTheme="outline"></i>
                        </nz-tag>
                        Actions
                    </nz-list-header>
                    <nz-list-item *ngFor="let item of displayedActions" class="list-content" (dblclick)="openPostModal(item)">
                        <div class="post-title">
                            <div class="title-container">
                                <div class="post">
                                    <span>#{{ item.postId }} {{ item.title }}</span>
                                    <div class="right-post">
                                        <ng-container *ngIf="item.isImportant">
                                            <nz-tag nzColor="error">
                                                <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip="Important"></i>
                                            </nz-tag>
                                        </ng-container>
                                        <div [ngSwitch]="item.actionStatusId" class="action-status">
                                            <ng-container *ngSwitchCase="actionStatus.PENDING">
                                                <nz-tag nzColor="default">
                                                    {{ item.actionStatusText }}
                                                </nz-tag>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="actionStatus.ONGOING">
                                                <nz-tag [nzColor]="'gold'">
                                                    {{ item.actionStatusText }}
                                                </nz-tag>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="actionStatus.COMPLETED">
                                                <nz-tag [nzColor]="'blue'">
                                                    {{ item.actionStatusText }}
                                                </nz-tag>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="actionStatus.CANCELED">
                                                <nz-tag nzColor="error">
                                                    {{ item.actionStatusText }}
                                                </nz-tag>
                                            </ng-container>
                                        </div>
                                        <a nz-button nzType="link" (click)="openSendMailModal( item )" nz-tooltip="Envoyer">
                                            <i nz-icon nzType="mail" nzTheme="outline"></i>
                                        </a>
                                        <a nz-button nzType="link" (click)="openEditPostModal( item )" nz-tooltip="Modifier">
                                            <i nz-icon nzType="edit" nzTheme="outline"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="post-dates">
                                <span class="owner"> - {{ item.ownerUserName }} le {{ formatDate( item.creationDate ) }}</span>
                                <span class="owner" *ngIf="formatDate(item.lastEditionDate) !== '-'">Modifié le : {{ formatDate( item.lastEditionDate ) }}</span>
                            </div>
                        </div>
                    </nz-list-item>
                    <nz-list-footer class="list-footer">
                        <a class="add-button" nz-button nzType="link" (click)="openCreatePostModal( 'Action' )">                            
                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                            Ajouter une action
                        </a>
                    </nz-list-footer>
                </nz-list>
            </div>
            <div class="lists">
                <nz-list nzBordered nzSize="large" class="list">
                    <nz-list-header class="list-header">
                        <nz-tag [nzColor]="'gold'">
                            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                        </nz-tag>
                        Informations
                    </nz-list-header>
                    <nz-list-item *ngFor="let item of displayedInfos" class="list-content" (dblclick)="openPostModal(item)">
                        <div class="post-title">
                            <div class="post">
                                <span>#{{ item.postId }} {{ item.title }}</span>
                                <div class="right-post">
                                    <ng-container *ngIf="item.isImportant">
                                        <nz-tag nzColor="error">
                                            <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip="Important"></i>
                                        </nz-tag>
                                    </ng-container>
                                    <a nz-button nzType="link" (click)="openSendMailModal( item )" nz-tooltip="Envoyer">
                                        <i nz-icon nzType="mail" nzTheme="outline"></i>
                                    </a>
                                    <a nz-button nzType="link" (click)="openEditPostModal( item )" nz-tooltip="Modifier">
                                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="post-dates">
                                <span class="owner"> - {{ item.ownerUserName }} le {{ formatDate( item.creationDate ) }}</span>
                                <span class="owner" *ngIf="formatDate(item.lastEditionDate) !== '-'">Modifié le : {{ formatDate( item.lastEditionDate ) }}</span>
                            </div>
                        </div>
                    </nz-list-item>
                    <nz-list-footer class="list-footer">                        
                        <a class="add-button" nz-button nzType="link" (click)="openCreatePostModal( 'Information' )">
                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                            Ajouter une information
                        </a>
                    </nz-list-footer>
                </nz-list>
            </div>
        </div>
    </ng-container>
</div>

<app-generic-modal 
    #modalComponent
    (formValidated)="modalFormValidated($event)"
></app-generic-modal>

<ng-container *ngIf="selectedPost">
    <app-post-modal 
        [post]="selectedPost"
        [isVisible]="showPostModal"
        (modalClosed)="closeModal()"
    ></app-post-modal>
</ng-container>