<div class="mail-form-container">
    <ng-container *ngIf="formGroup">
        <form nz-form [formGroup]="formGroup">
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label [nzRequired]="true">Destinataire</nz-form-label>
                    <nz-input-group>
                        <nz-select 
                            class="template-select"
                            nzMode="tags"
                            [nzTokenSeparators]="[';']"
                            formControlName="destination">
                            <nz-option-group [nzLabel]="'Listes de diffusions'">
                                <nz-option *ngFor="let m of mailingLists" 
                                    [nzLabel]="m.mailingListName" 
                                    [nzValue]="m.mailingListName"
                                ></nz-option>
                            </nz-option-group>
                            <nz-option-group [nzLabel]="'Membres'">
                                <nz-option *ngFor="let m of membersEmail" 
                                    [nzLabel]="getMemberEmailDisplay( m )" 
                                    [nzValue]="m.email"
                                ></nz-option>
                            </nz-option-group>
                    </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label>Modèle de mail</nz-form-label>
                    <nz-input-group>
                        <nz-select 
                            class="template-select"
                            [nzDropdownMatchSelectWidth]="false" 
                            [(ngModel)]="selectedTemplate" 
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="templateChanged($event)">
                            <nz-option *ngFor="let t of templates" [nzValue]="t.templateResId" [nzLabel]="t.templateName"></nz-option>
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label [nzRequired]="true">Contenu de l'e-mail</nz-form-label>
                    <nz-input-group>
                        <angular-editor #editor formControlName="mailContent" [config]="editorConfig"></angular-editor>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
</div>