import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouterHistoryService } from 'src/app/core/services/routerHistoryService/router-history.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component( {
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
} )
export class SidebarComponent implements OnInit, OnDestroy {
  @Output() collapsed = new EventEmitter<boolean>();

  private _subscriptions: Array<Subscription>;
  private _currentUrl: string | null;
  public isCollapsed: boolean;

  constructor (
    private _routerHistory: RouterHistoryService,
    private _userService: UserService
  ) {
    this._subscriptions = new Array<Subscription>();
    this.isCollapsed = false;
    this._currentUrl = null;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._routerHistory.currentUrl$.subscribe( ( url: string | null ) => { this._currentUrl = url; } )
    );
  }

  toggleCollapse( b: boolean ): void {
    this.isCollapsed = b;
    this.collapsed.emit( b );
  }

  isSelected( path: string ): boolean {
    return path === this._currentUrl?.split( '?' )[0] || path === `/${this._currentUrl?.split( '/' )[1]}`;
  }

  isAdmin(): boolean {
    if ( this._userService.isAdmin() || this._userService.isSignatureAdmin() ) {
      return true;
    }
    return false;
  }
}
