import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserAggregate } from 'src/app/admin/shared/models/user.model';
import { AdminApiService } from 'src/app/core/api/admin-api.service';
import { UserApiService } from 'src/app/core/api/user-api.service';
import { NotificationHubService } from './notification-hub.service';

@Injectable( {
  providedIn: 'root'
} )
export class UserService implements OnInit {
  private _userInfos: BehaviorSubject<UserAggregate | undefined>;
  private _isAdmin: boolean;
  private _isSignatureAdmin: boolean;
  private _isSignatureUser: boolean;
  public userInfos$: Observable<UserAggregate | undefined>;
  private _privateMode: BehaviorSubject<boolean>;
  public privateMode$: Observable<boolean>;

  constructor (
    private _userApiService: UserApiService,
    private _hubService: NotificationHubService
  ) {
    this._userInfos = new BehaviorSubject<UserAggregate | undefined>( undefined );
    this.userInfos$ = this._userInfos.asObservable();
    this._isAdmin = false;
    this._isSignatureAdmin = false;
    this._privateMode = new BehaviorSubject<boolean>( false );
    this.privateMode$ = this._privateMode.asObservable();
    this._isSignatureUser = false;
  }

  ngOnInit(): void {
    this.setUserContext();
  }

  public setUserContext(): void {
    this._userApiService.getCurrentUserInfos().pipe( first() ).subscribe( ( userInfos: UserAggregate ) => {
      this._userInfos.next( userInfos );
      this._isAdmin = userInfos.isAdmin;
      this._isSignatureAdmin = this._userInfos.value.groups.findIndex( g => g.groupName === 'Signature.Admins' ) > -1;
      this._isSignatureUser = userInfos.groups.find( g => g.groupName.includes( 'Signature' ) ) !== undefined;

      this._hubService.startConnection();
      this._hubService.addNotifListener();
    } );
  }

  isAdmin(): boolean {
    return this._isAdmin;
  }

  isSignatureAdmin(): boolean {
    return this._isSignatureAdmin;
  }

  getUser(): UserAggregate {
    return this._userInfos.value;
  }

  isSignatureUser(): boolean {
    return this._isSignatureUser;
  }

  public setPrivateMode( bool: boolean ): void {
    this._privateMode.next( bool );
  }
}
