import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { MailingList, MemberEmail } from '../../shared/models/project.model';

@Component( {
  selector: 'app-mailing-list-form',
  templateUrl: './mailing-list-form.component.html',
  styleUrls: ['./mailing-list-form.component.less']
} )
export class MailingListFormComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() type: string;
  @Input() selectedList: MailingList;

  get getProjectId(): number {
    return this._projectId;
  }
  @Input() set projectId( id: number ) {
    if ( typeof id !== 'undefined' ) {
      this._projectId = id;
      this.getMembersEmail( id );
    }
  }

  @Output() modalClosed = new EventEmitter<void>();

  private _projectId: number;
  public membersEmails: Array<MemberEmail>;

  constructor (
    private _projectApiService: ProjectApiService
  ) {
    this.membersEmails = [];
  }

  ngOnInit(): void {
  }

  getMembersEmail( projectId: number ): void {
    this._projectApiService.getMembersEmail( projectId ).pipe( first() ).subscribe( res => {
      this.membersEmails = [...res];
    } );
  }

  getMemberLabel( m: MemberEmail ): string {
    if ( m.firstName.length > 0 || m.lastName.length > 0 ) {
      return `${m.email} (${m.firstName} ${m.lastName})`;
    }
    return `${m.email} (${m.userName})`;
  }

  getFormValue(): FormGroup {
    return this.formGroup;
  }

  shouldDisable( m: MemberEmail ): boolean {
    return this.selectedList?.members.findIndex( member => member.userId === m.userId ) > - 1;
  }
}
