import { Inject, Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { AuthService } from '@signature/webfrontauth';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from '../models/notification.model';
import { AXIOS } from '@signature/webfrontauth-ngx';
import { AxiosInstance } from 'axios';

@Injectable( {
  providedIn: 'root'
} )
export class NotificationHubService {

  private _hubConnection: signalR.HubConnection;
  private _newNotif: BehaviorSubject<Notification>;
  public newNotif$: Observable<Notification>;
  private _hasUnreadNotifs: BehaviorSubject<boolean>;
  public hasUnreadNotifs$: Observable<boolean>;

  constructor (
    private _authService: AuthService,
    @Inject( AXIOS ) axios: AxiosInstance
  ) {
    this._newNotif = new BehaviorSubject<Notification>( null );
    this.newNotif$ = this._newNotif.asObservable();

    this._hasUnreadNotifs = new BehaviorSubject<boolean>( false );
    this.hasUnreadNotifs$ = this._hasUnreadNotifs.asObservable();

    // todo: this._authService.addOnChange
  }

  public startConnection(): void {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl( `/notif`, { accessTokenFactory: () => this._authService.token } )
      .build();

    this._hubConnection
      .start()
      .catch( err => console.log( 'Error while starting connection: ' + err ) )
  }

  public stopConnection(): void {
    this._hubConnection.stop();
  }

  public setHasUnreadNotifs( bool: boolean ): void {
    this._hasUnreadNotifs.next( bool );
  }

  public addNotifListener(): void {
    this._hubConnection.on( 'newNotif', ( n ) => {
      console.log( n )
      this._newNotif.next( n );
      this._hasUnreadNotifs.next( true );
    } );
  }
}
