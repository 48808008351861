import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component( {
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less']
} )
export class HomePageComponent implements OnInit {
  public collapsed: boolean;

  constructor ( private _userService: UserService ) {
    this.collapsed = false;
  }

  ngOnInit(): void {
    this._userService.setUserContext();
  }

  isCollapsed( b: boolean ) {
    this.collapsed = b;
  }
}
