import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@signature/webfrontauth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { ErrorCommandResponse } from 'src/app/core/crs/error-response.model';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { ValidatedForm } from 'src/app/shared/models/form-validation.model';
import { AddRecipientToMailingListCommand } from '../../shared/commands/project/add-recipient-to-mailing-list.command';
import { CreateMailingListCommand } from '../../shared/commands/project/create-mailing-list.command';
import { DestroyMailingListCommand } from '../../shared/commands/project/destroy-mailing-list.command';
import { RemoveFromMailingListCommand } from '../../shared/commands/project/remove-recipient-from-mailing-list.command';
import { MailingList, MemberEmail } from '../../shared/models/project.model';
import { MailingListFormComponent } from '../mailing-list-form/mailing-list-form.component';

@Component( {
  selector: 'app-mailing-list-modal',
  templateUrl: './mailing-list-modal.component.html',
  styleUrls: ['./mailing-list-modal.component.less']
} )
export class MailingListModalComponent implements OnInit {
  @ViewChild( 'modalComponent' ) modalComponent: GenericModalComponent;

  @Input() isVisible: boolean;

  get getProjectId(): number {
    return this._projectId;
  }
  @Input() set projectId( id: number ) {
    if ( typeof id !== 'undefined' ) {
      this._projectId = id;
      this.getMailingLists( id, true );
    }
  }
  @Output() modalClosed = new EventEmitter<void>();

  private _projectId: number;
  public mailingLists: Array<MailingList>;
  public selectedList: MailingList;

  constructor (
    private _formBuilder: FormBuilder,
    private _crsService: CrsService,
    private _nzMessageService: NzMessageService,
    private _authService: AuthService,
    private _projectApiService: ProjectApiService,
    private _modalService: NzModalService
  ) {
    this.mailingLists = new Array<MailingList>();
    this.selectedList = undefined;
  }

  ngOnInit(): void {
  }

  getMailingLists( projectId: number, firstVisit: boolean = false ): void {
    this._projectApiService.getMailingLists( projectId ).subscribe( ml => {
      this.mailingLists = [...ml];
      if ( firstVisit ) {
        this.selectedList = ml[0];
      } else {
        this.selectedList = this.mailingLists.find( m => m.mailingListId === Math.max( ...this.mailingLists.map( m => m.mailingListId ) ) );
      }
    } );
  }

  closeModal(): void {
    this.modalClosed.emit();
  }

  isSelected( listName: string ): boolean {
    return this.selectedList.mailingListName === listName;
  }

  setSelected( list: MailingList ): void {
    this.selectedList = list;
  }

  createListFormGroup(): FormGroup {
    return this._formBuilder.group( {
      listName: ['', Validators.required]
    } );
  }

  createMemberFormGroup(): FormGroup {
    return this._formBuilder.group( {
      email: [''],
      firstName: [''],
      lastName: [''],
      users: [[]]
    } );
  }

  openCreateListModal(): void {
    const title = `Nouvelle liste`;
    const form: FormGroup = this.createListFormGroup();
    const componentParams = { formGroup: form, type: 'createList', projectId: this.getProjectId, selectedList: this.selectedList };
    this.modalComponent.openFormModal( title, MailingListFormComponent, componentParams, 'createMailingList' );
  }

  openAddMemberModal(): void {
    const title = `Nouveau destinataire`;
    const form: FormGroup = this.createMemberFormGroup();
    const componentParams = { formGroup: form, type: 'addUser', projectId: this.getProjectId, selectedList: this.selectedList };
    this.modalComponent.openFormModal( title, MailingListFormComponent, componentParams, 'addUserToMailingList' );
  }

  modalFormValidated( result: ValidatedForm ): void {
    if ( result.action === 'createMailingList' ) {
      if ( result.form.valid ) {
        const command = new CreateMailingListCommand(
          this._authService.authenticationInfo.user.userId,
          this.getProjectId,
          result.form.get( 'listName' ).value
        );

        this._crsService.send<DefaultCommandResponse>( command ).then( res => {
          if ( res.success ) {
            this._nzMessageService.success( 'La liste de diffusion a été créée avec succès.' );
          } else {
            this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
          }

          this.getMailingLists( this.getProjectId );
        } );
      }
    } else if ( result.action === 'addUserToMailingList' ) {
      var regexp = new RegExp( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
      if ( !result.form.get( 'users' ).value && !result.form.get( 'email' ).value ) {
        this._nzMessageService.error( 'Vous devez renseigner un email ou selectionner un utilsateur.' );
      } else if ( result.form.get( 'users' ).value.length === 0 && !regexp.exec( result.form.get( 'email' ).value ) ) {
        this._nzMessageService.error( 'L\'email renseigné n\'est pas correct.' );
      } else {
        const command = new AddRecipientToMailingListCommand(
          this._authService.authenticationInfo.user.userId,
          this.selectedList.mailingListId,
          result.form.get( 'users' ).value,
          result.form.get( 'email' ).value,
          result.form.get( 'firstName' ).value,
          result.form.get( 'lastName' ).value,
        );

        this._crsService.send<DefaultCommandResponse>( command ).then( res => {
          if ( res.success ) {
            this._nzMessageService.success( `L'ajout s'est effectué avec succès.` );
          } else {
            this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
          }
          this.getMailingLists( this.getProjectId );
        } );
      }
    }
  }

  removeFromList( member: MemberEmail ): void {
    const opts: ModalOptions = {
      nzTitle: `Êtes vous sûr(e) de vouloir retirer ce destinataire ?`,
      nzOnOk: () => {
        const command = new RemoveFromMailingListCommand(
          this._authService.authenticationInfo.user.userId,
          this.selectedList.mailingListId,
          member.userId
        );

        this._crsService.send<DefaultCommandResponse>( command ).then( res => {
          if ( res.success ) {
            this._nzMessageService.success( `L'utilisateur a bien été retiré de la liste.` );
          } else {
            this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
          }
          this.getMailingLists( this.getProjectId );
        } );
      }
    }

    this._modalService.confirm( opts );
  }

  destroyList( list: MailingList ): void {
    const opts: ModalOptions = {
      nzTitle: `Êtes vous sûr(e) de vouloir supprimer cette liste de diffusion ?`,
      nzOnOk: () => {
        const command = new DestroyMailingListCommand(
          this._authService.authenticationInfo.user.userId,
          list.mailingListId
        );

        this._crsService.send<DefaultCommandResponse>( command ).then( res => {
          if ( res.success ) {
            this._nzMessageService.success( `La liste de diffusion a bien été supprimée.` );
          } else {
            this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
          }
          this.getMailingLists( this.getProjectId );
        } );
      }
    }

    this._modalService.confirm( opts );
  }
}
