<nz-modal 
    [(nzVisible)]="isVisible" 
    [nzWidth]="900"
    [nzTitle]="'Gestion des listes de diffusion'" 
    class="mailing-list-modal"
    [nzBodyStyle]="{ 'max-height': '900px' }"
    (nzOnCancel)="closeModal()">

    <ng-container *nzModalContent>
        <div class="modal-content">
            <ul nz-menu>
                <li nz-menu-item *ngFor="let list of mailingLists" [nzSelected]="isSelected(list.mailingListName)" (click)="setSelected( list )">
                    <div>
                        {{ list.mailingListName }}
                        <a nz-button nzType="link" nz-tooltip nzTooltipTitle="Supprimer" (click)="destroyList( list )">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </a>
                    </div>
                </li>
                <li>
                    <a nz-button nzType="link" (click)="openCreateListModal()">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        Ajouter une liste
                    </a>
                </li>
            </ul>
            <div class="list-members" *ngIf="selectedList">
                <nz-list>
                    <nz-list-item *ngFor="let member of selectedList.members">
                        <nz-list-item-meta [nzDescription]="member.email">
                            <nz-list-item-meta-title>
                                {{ member.firstName }} {{ member.lastName }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <a nz-button nzType="link" nz-tooltip nzTooltipTitle="Retirer" (click)="removeFromList( member )">
                            <i nz-icon nzType="close" nzTheme="outline"></i>                            
                        </a>
                    </nz-list-item>
                </nz-list>
                <a nz-button nzType="link" class="add-member" (click)="openAddMemberModal()">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    Ajouter un destinataire
                </a>
            </div>
        </div>
    </ng-container>

    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="closeModal()">Terminer</button>
    </div>
</nz-modal>

<app-generic-modal
    #modalComponent
    (formValidated)="modalFormValidated($event)"
></app-generic-modal>