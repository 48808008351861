import { Command } from "@signature/crs-client";

@Command( 'RemoveFromMailingList' )
export class RemoveFromMailingListCommand {
    constructor (
        public actorId: number,
        public mailingListId: number,
        public memberId: number
    ) {
    }
}