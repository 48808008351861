import { DateTime } from "luxon";

export function dateToLocalFR( date: string ) {
    if ( date === '0001-01-01T00:00:00' ) {
        return '-';
    }
    const dateTime = DateTime.fromISO( date, { zone: 'utc' } ).setZone( 'Europe/Paris' );
    return dateTime.toFormat( 'dd/MM/yyyy HH:mm:ss' );
}

export function dateToLocalFRWithoutHours( date: string ) {
    if ( date === '0001-01-01T00:00:00' ) {
        return '-';
    }
    const dateTime = DateTime.fromISO( date, { zone: 'utc' } ).setZone( 'Europe/Paris' );
    return dateTime.toFormat( 'dd/MM/yyyy' );
}