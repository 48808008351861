import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ProjectUser } from 'src/app/admin/shared/models/user.model';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { PostType } from '../../shared/models/project.model';

@Component( {
  selector: 'app-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.less']
} )
export class PostFormComponent implements OnInit {
  @Input() set projectId( id: number ) {
    if ( typeof id !== 'undefined' ) {
      this._projectId = id;
      this.getMembers( id );
    }
  }
  get getProjectId(): number {
    return this._projectId;
  }
  @Input() postForm: FormGroup;
  @Input() selectedType: string = '';

  private _projectId: number;
  public postTypes: Array<PostType>;
  public members: Array<ProjectUser>;

  constructor (
    private _projectApiService: ProjectApiService
  ) {
    this.postTypes = new Array<PostType>();
    this.members = new Array<ProjectUser>();
  }

  ngOnInit(): void {
    this._projectApiService.getPostTypes().pipe( first() ).subscribe( types => {
      this.postTypes = [...types];

      if ( this.selectedType && this.selectedType.length > 0 ) {
        this.postForm.patchValue( {
          postType: this.postTypes.find( t => t.type === this.selectedType ).postTypeId
        } );
      }
    } );
  }

  getMembers( projectId: number ): void {
    this._projectApiService.getProjectMembers( projectId ).pipe( first() ).subscribe( members => {
      this.members = [...members];
    } );
  }

  getFormValue(): FormGroup {
    return this.postForm;
  }

  setError(): void {
    this.postForm.updateValueAndValidity();
  }

  shouldShowDeadLine(): boolean {
    return this.postForm.get( 'postType' )!.value === this.postTypes.find( p => p.type === 'Action' )?.postTypeId;
  }

  getMemberLabel( m: ProjectUser ): string {
    return `${m.firstName} ${m.lastName} (${m.userName})`;
  }
}
