import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@signature/webfrontauth';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UserApiService } from 'src/app/core/api/user-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { DeleteAllNotifsCommand } from 'src/app/projects/shared/commands/user/delete-all-notifs.command';
import { DeleteNotifCommand } from 'src/app/projects/shared/commands/user/delete-notif.command';
import { ReadNotificationsCommand } from 'src/app/projects/shared/commands/user/read-notifications.command';
import { Notification } from 'src/app/shared/models/notification.model';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';

@Component( {
  selector: 'app-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['./notification-drawer.component.less']
} )
export class NotificationDrawerComponent implements OnInit, OnDestroy {

  private _subs: Array<Subscription>;
  public isVisible: boolean;
  public notifs: Array<Notification>;

  constructor (
    private _userApiService: UserApiService,
    private _router: Router,
    private _crsService: CrsService,
    private _authService: AuthService,
    private _hubService: NotificationHubService
  ) {
    this.isVisible = false;
    this.notifs = [];
    this._subs = [];
  }

  ngOnDestroy(): void {
    this._subs.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this.getUserNotifications();
    this._subs.push(
      this._hubService.newNotif$.pipe( filter( n => n !== null ) ).subscribe( n => this.notifs.push( n ) )
    );
  }

  async openDrawer(): Promise<void> {
    this.getUserNotifications();
    this.isVisible = true;

    await this._crsService.send( new ReadNotificationsCommand( this._authService.authenticationInfo.user.userId ) );
  }

  close(): void {
    this.isVisible = false;
  }

  getUserNotifications(): void {
    this._userApiService.getUsersNotifications().pipe( first() ).subscribe( ( notifs ) => {
      this.notifs = [...notifs];
      if ( notifs.filter( n => !n.hasRead ).length > 0 ) {
        this._hubService.setHasUnreadNotifs( true );
      }
    } );
  }

  goTo( n: Notification ) {
    this._router.navigate( [`${n.targetUrl}`] );
    this.close();
  }

  async deleteAllNotifs(): Promise<void> {
    await this._crsService.send( new DeleteAllNotifsCommand( this._authService.authenticationInfo.user.userId ) ).then( _ => {
      this.getUserNotifications();
    } );
  }

  async deleteNotif( notifId: number ): Promise<void> {
    await this._crsService.send( new DeleteNotifCommand( this._authService.authenticationInfo.user.userId, notifId ) ).then( _ => {
      this.getUserNotifications();
    } );
  }
}
