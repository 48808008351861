import { Command } from "@signature/crs-client";

@Command( 'SendReportByMail' )
export class SendReportByMailCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public startDate: string,
        public endDate: string,
        public mailContent: string,
        public destination: Array<string>,
        public privateMode: boolean
    ) {
    }
}