import { Command } from "@signature/crs-client";

@Command( 'UnassignUser' )
export class UnassignUserCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public postId: number,
        public userId: number
    ) {
    }
}