<div class="post-form-container">
    <ng-container *ngIf="postForm">
        <form nz-form [formGroup]="postForm">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="typeError">
                    <nz-form-label [nzRequired]="true">Type</nz-form-label>
                    <nz-input-group>
                        <ng-container *ngIf="postTypes.length > 0">
                            <nz-select class="type-select" nzShowSearch nzAllowClear nzPlaceHolder="Sélectionner un type" formControlName="postType">
                                <ng-container *ngFor="let t of postTypes">
                                    <nz-option [nzLabel]="t.type" [nzValue]="t.postTypeId"></nz-option>
                                </ng-container>
                            </nz-select>
                        </ng-container>
                    </nz-input-group>
                    <ng-template #typeError>
                      <ng-container *ngIf="postForm.get('postType')!.hasError('required') || postForm.get('postType')!.hasError('min')">
                        Veuillez sélectionner un type
                      </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="contentError">
                    <nz-form-label [nzRequired]="true">Titre</nz-form-label>
                    <nz-input-group>
                        <nz-textarea-count [nzMaxCharacterCount]="255">
                            <textarea rows="1" formControlName="postContent" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-input-group>
                    <ng-template #contentError>
                        <ng-container *ngIf="postForm.get('postContent')!.hasError('required')">
                            Veuillez renseigner un contenu.
                        </ng-container>
                        <ng-container *ngIf="postForm.get('postContent')!.hasError('maxlength')">
                            La limite de caractère est de 255.
                        </ng-container>
                        <ng-container *ngIf="postForm.get('postContent')!.hasError('minlength')">
                            Le contenu du post doit au moins contenir 1 caractère.
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label>Description</nz-form-label>
                    <nz-input-group>
                        <nz-textarea-count [nzMaxCharacterCount]="400">
                            <textarea rows="4" formControlName="description" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label [nzRequired]="true">Date effective</nz-form-label>  
                    <nz-date-picker class="date-picker" formControlName="openedDate" nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label [nzRequired]="true">Assigné à</nz-form-label>
                    <nz-select formControlName="assignedUsers" nzMode="multiple" nzShowSearch nzAllowClear>
                        <nz-option *ngFor="let m of members" [nzLabel]="getMemberLabel( m )" [nzValue]="m.userId"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <ng-container *ngIf="shouldShowDeadLine()">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label [nzRequired]="true">Délai</nz-form-label>  
                        <nz-date-picker class="date-picker" formControlName="deadLine" nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label>Important</nz-form-label>  
                    <nz-switch formControlName="isImportant"></nz-switch>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label>Privé</nz-form-label>  
                    <nz-switch formControlName="isPrivate"></nz-switch>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
</div>