import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { ProjectUser, User } from 'src/app/admin/shared/models/user.model';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { RouterHistoryService } from 'src/app/core/services/routerHistoryService/router-history.service';
import { Post, ProjectData, ProjectStatus } from '../../shared/models/project.model';
import { dateToLocalFR } from '../../../shared/date-helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostFormComponent } from '../../components/post-form/post-form.component';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { CreatePostCommand } from '../../shared/commands/post/create-post.command';
import { AuthService } from '@signature/webfrontauth';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { CrsService } from 'src/app/core/crs/crs.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { PROJECT_STATUS } from '../../shared/models/project-status.model';
import { EditProjectStatusCommand } from '../../shared/commands/project/edit-project-status.command';
import { EditProjectCommand } from '../../shared/commands/project/edit-project.command';
import { RestoreProjectCommand } from '../../shared/commands/project/restore-project.command';
import { ArchiveProjectCommand } from '../../shared/commands/project/archive-project.command';
import { ProjectFormComponent } from '../../components/project-form/project-form.component';
import { UserService } from 'src/app/shared/services/user.service';
import { GroupInfos } from 'src/app/admin/shared/models/user.model';
import { ChangeMemberGroupCommand } from '../../shared/commands/project/change-member-group.command';
import { AddMemberCommand } from '../../shared/commands/project/add-member.command';
import { RemoveMemberCommand } from '../../shared/commands/project/remove-member.command';
import { PostDisplayComponent } from '../../components/post-display/post-display.component';

@Component( {
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.less']
} )
export class ProjectViewComponent implements OnInit, OnDestroy {
  @ViewChild( 'modalComponent' ) modalComponent: GenericModalComponent;
  @ViewChild( 'postDisplayComponent' ) postDisplayComponent: PostDisplayComponent;

  private _subscriptions: Array<Subscription>;
  private _postId?: number;
  public projectId: number;
  public projectData?: ProjectData;
  public projectStatus = PROJECT_STATUS;
  public selectedStatus: number;
  public allStatus: Array<ProjectStatus>;
  public editingProjectStatus: boolean;
  public currentUserIsViewer: boolean;
  public isHandlingMembers: boolean;
  public isAddingMember: boolean;
  public isEditingMember: boolean;
  public projectGroups?: Array<GroupInfos>;
  public selectedMember?: ProjectUser;
  public memberForm?: FormGroup;
  public potentialMembers?: Array<User>;
  public isHandlingMailingList: boolean;

  constructor (
    private _router: Router,
    private _routerHistoryService: RouterHistoryService,
    private _projectApiService: ProjectApiService,
    private _authService: AuthService,
    private _nzMessageService: NzMessageService,
    private _userService: UserService,
    private _crsService: CrsService,
    private _formBuilder: FormBuilder
  ) {
    this._subscriptions = new Array<Subscription>();
    this.allStatus = new Array<ProjectStatus>();
    this.editingProjectStatus = false;

    this.currentUserIsViewer = true;
    this.isHandlingMembers = false;
    this.isAddingMember = false;
    this.isEditingMember = false;
    this.isHandlingMailingList = false;
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._routerHistoryService.currentUrl$.pipe(
        switchMap( ( url: string ): Observable<ProjectData> => {
          const projectId = parseInt( url.split( '/projects/' )[1] );
          this.projectId = projectId;
          const postId = parseInt( url.split( '/post/' )[1] );
          this._postId = postId === NaN ? undefined : postId;
          return this.getProjectDataSubscription( projectId );
        } ),
        switchMap( ( projectData => {
          this.projectData = { ...projectData };

          return this._projectApiService.getProjectStatus().pipe( first() );
        } ) )
      ).subscribe( s => {
        this.allStatus = [...s];
        this.selectedStatus = s.find( s => s.projectStatusId === this.projectData.projectStatusId ).projectStatusId;

        let post: Post = this.projectData.actions.find( a => a.postId === this._postId );
        if ( post === undefined ) {
          post = this.projectData.decisions.find( d => d.postId === this._postId );
          if ( post === undefined ) {
            post = this.projectData.informations.find( i => i.postId === this._postId );
          }
        }
        this.postDisplayComponent.openPostModal( post );
      } )
    );

    this._subscriptions.push(
      this._userService.userInfos$.pipe( filter( u => u !== undefined ) ).subscribe( u => {
        if ( u.isAdmin ||
          u.groups.filter( g => g.zoneId === this.projectId && !g.groupName.includes( 'Viewers' ) ).length > 0 ||
          u.groups.filter( g => g.groupName === 'Signature.Editors' || g.groupName === 'Signature.Admins' ).length > 0 ) {
          this.currentUserIsViewer = false;
        }
      } )
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach( s => s.unsubscribe() );
  }

  getProjectGroups(): void {
    this._projectApiService.getProjectGroups( this.projectId ).pipe( first() ).subscribe( g => {
      this.projectGroups = [...g];
    } );
  }

  getProjectData( projectId: number ) {
    this.getProjectDataSubscription( projectId )
      .pipe( first() )
      .subscribe( p => {
        this.projectData = { ...p };
        this.selectedStatus = this.allStatus.find( s => s.projectStatusId === p.projectStatusId ).projectStatusId;
      } );
  }

  getProjectDataSubscription( projectId: number ): Observable<ProjectData> {
    return this._projectApiService.getProjectData( projectId );
  }

  getMemberTooltip( user: ProjectUser ): string {
    return `${user.firstName} ${user.lastName} (${user.userName}) - ${user.role}`;
  }

  getMemberClass( user: ProjectUser ): string {
    let cssClass = 'member ';

    if ( user.role === 'Admins' ) {
      cssClass += 'admins';
    } else if ( user.role === 'Editors' ) {
      cssClass += 'editors';
    } else {
      cssClass += 'viewers';
    }
    return cssClass;
  }

  formatDate( date: Date ): string {
    return dateToLocalFR( date.toString() );
  }

  createPostFormGroup(): FormGroup {
    return this._formBuilder.group( {
      postType: [0, [Validators.required, Validators.min( 1 )]],
      postContent: ['', [Validators.required, Validators.minLength( 1 ), Validators.maxLength( 255 )]],
      description: [''],
      isImportant: [false],
      isPrivate: [false],
      openedDate: [new Date(), Validators.required],
      assignedUsers: [[], Validators.required],
      deadLine: [null]
    } );
  }

  openCreatePostModal(): void {
    const title = `Nouveau post`;
    const form: FormGroup = this.createPostFormGroup();
    const componentParams = { projectId: this.projectId, postForm: form };
    this.modalComponent.openFormModal( title, PostFormComponent, componentParams, 'createPost' );
  }

  modalFormValidated( result: { form: FormGroup, action: string } ): void {
    let successMsg = `La création s'est déroulée avec succès.`;
    let errorMsg = `Une erreur est survenue lors de la création.`;

    if ( result.action === 'editProject' ) {
      successMsg = 'La modification a bien été effectuée.';
      errorMsg = 'Une erreur est survenue lors de l\'édition';
    }
    const command = this.createCrsCommand( result.form, result.action );
    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.getProjectData( this.projectId );
    } );
  }

  createCrsCommand( form: FormGroup, actionName: string ) {
    let command = null;
    switch ( actionName ) {
      case 'createPost':
        command = new CreatePostCommand(
          this._authService.authenticationInfo.user.userId,
          this.projectId,
          form.get( 'postType' ).value,
          form.get( 'postContent' ).value,
          form.get( 'description' ).value,
          form.get( 'isImportant' ).value,
          form.get( 'isPrivate' ).value,
          form.get( 'openedDate' ).value,
          form.get( 'assignedUsers' ).value,
          form.get( 'deadLine' ).value
        );
        break;
      case 'editProject':
        command = new EditProjectCommand(
          this._authService.authenticationInfo.user.userId,
          this.projectId,
          form.get( 'projectName' ).value,
          form.get( 'description' ).value,
          form.get( 'isPrivate' ).value
        );
        break;
      default:
        break;
    }

    return command;
  }

  onBack(): void {
    this._router.navigate( ['/projects'] );
  }

  editProjectStatus(): void {
    const successMsg = `La modification s'est déroulée avec succès.`;
    const errorMsg = `Une erreur est survenue lors de la modification.`;

    const command = new EditProjectStatusCommand(
      this._authService.authenticationInfo.user.userId,
      this.projectId,
      this.selectedStatus
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.getProjectData( this.projectId );
      this.editingProjectStatus = false;
    } );
  }

  openEditProjectModal(): void {
    const title = `Modification du projet ${this.projectData.projectName}`;
    const form: FormGroup = this.createEditProjectFormGroup();
    const componentParams = { projectForm: form };
    this.modalComponent.openFormModal( title, ProjectFormComponent, componentParams, 'editProject' );
  }

  createEditProjectFormGroup(): FormGroup {
    return this._formBuilder.group( {
      projectName: [this.projectData.projectName, Validators.required],
      description: [this.projectData.description],
      isPrivate: [this.projectData.isPrivate]
    } );
  }

  archiveProject(): void {
    const successMsg = `L'archivage s'est déroulée avec succès.`;
    const errorMsg = `Une erreur est survenue lors de l'archivage.`;

    const command = new ArchiveProjectCommand(
      this._authService.authenticationInfo.user.userId,
      this.projectId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.getProjectData( this.projectId );
      this.editingProjectStatus = false;
    } );
  }

  restoreProject(): void {
    const successMsg = `La restauration s'est déroulée avec succès.`;
    const errorMsg = `Une erreur est survenue lors de la restauration.`;

    const command = new RestoreProjectCommand(
      this._authService.authenticationInfo.user.userId,
      this.projectId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.getProjectData( this.projectId );
    } );
  }

  cancelHandle(): void {
    this.isHandlingMembers = false;
    this.isAddingMember = false;
    this.isEditingMember = false;

    if ( this.selectedMember ) {
      this.selectedMember = undefined;
    }

    this.memberForm = undefined;
  }

  addUserToProject(): void {
    this.memberForm = this._formBuilder.group( {
      userId: [0, Validators.required],
      groupId: [0, Validators.required]
    } );

    const groups = this._projectApiService.getProjectGroups( this.projectId ).pipe( first() );
    const users = this._projectApiService.getPotentialMembers( this.projectId ).pipe( first() );
    forkJoin( [groups, users] ).subscribe( {
      next: ( result ) => {
        this.projectGroups = [...result[0]];
        this.potentialMembers = [...result[1]];
        this.isHandlingMembers = true;
        this.isAddingMember = true;
      }
    } );
  }

  modifyUserGroup( member: ProjectUser ): void {
    this.selectedMember = member;

    this._projectApiService.getProjectGroups( this.projectId ).pipe( first() ).subscribe( g => {
      this.projectGroups = [...g];
      this.isHandlingMembers = true;
      this.isEditingMember = true;

      const currentGroupId = g.find( g => g.groupName.includes( member.role ) ).groupId;
      this.memberForm = this._formBuilder.group( {
        userId: [member.userId, Validators.required],
        groupId: [currentGroupId, Validators.required]
      } );
    } );
  }

  shouldShowRemoveUser(): boolean {
    if ( this.selectedMember ) {
      return this.selectedMember.role !== 'Admins';
    }
    return false;
  }

  submitMemberForm(): void {
    let command;
    let successMsg = '';
    let errorMsg = '';

    if ( this.selectedMember ) {
      command = new ChangeMemberGroupCommand(
        this._authService.authenticationInfo.user.userId,
        this.projectId,
        this.memberForm.get( 'groupId' ).value,
        this.memberForm.get( 'userId' ).value
      );

      successMsg = `Le rôle de l'utilisateur a bien été modifié.`;
      errorMsg = `Une erreur est survenue lors de la modification du rôle l'utilisateur.`;
    } else {
      command = new AddMemberCommand(
        this._authService.authenticationInfo.user.userId,
        this.projectId,
        this.memberForm.get( 'groupId' ).value,
        this.memberForm.get( 'userId' ).value
      );

      successMsg = `L'utilisateur a bien été ajouté au projet.`;
      errorMsg = `Une erreur est survenue lors de l'ajout de l'utilisateur.`;
    }

    this._crsService.send<DefaultCommandResponse>( command ).then( res => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }

      this.getProjectData( this.projectId );
      this.cancelHandle();
    } );
  }

  removeUser(): void {
    let successMsg = `L'utilisateur a bien été retiré du projet.`;
    let errorMsg = `L'utilisateur n'a pas pu être retiré du projet.`;

    const command = new RemoveMemberCommand(
      this._authService.authenticationInfo.user.userId,
      this.projectId,
      this.memberForm.get( 'groupId' ).value,
      this.memberForm.get( 'userId' ).value
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( res => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }

      this.getProjectData( this.projectId );
      this.cancelHandle();
    } );
  }

  goToWorksheet(): void {
    this._router.navigate( [`projects/${this.projectId}/worksheet`] );
  }

  handleMailingList(): void {
    this.isHandlingMailingList = true;
  }

  closeMailingListModal(): void {
    this.isHandlingMailingList = false;
  }

  getUserInitials( m: ProjectUser ): string {
    if ( m.firstName.length > 0 && m.lastName.length > 0 ) {
      return `${m.firstName.substring( 0, 1 ).toLocaleUpperCase()}${m.lastName.substring( 0, 1 ).toLocaleUpperCase()}`
    }

    return `${m.userName.substring( 0, 1 ).toLocaleUpperCase()}`;
  }
}
