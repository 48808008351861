import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectViewComponent } from './pages/project-view/project-view.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { WorksheetComponent } from './pages/worksheet/worksheet.component';

const routes: Routes = [
  { path: '', component: ProjectsComponent },
  { path: ':projectId', component: ProjectViewComponent },
  { path: ':projectId/post/:postId', component: ProjectViewComponent },
  { path: ':projectId/worksheet', component: WorksheetComponent }
];

@NgModule( {
  imports: [RouterModule.forChild( routes )],
  exports: [RouterModule]
} )
export class ProjectsRoutingModule { }
