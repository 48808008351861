import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { AuthService } from '@signature/webfrontauth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { dateToLocalFR, dateToLocalFRWithoutHours } from 'src/app/shared/date-helper';
import { UserService } from 'src/app/shared/services/user.service';
import { ArchivePostCommand } from '../../shared/commands/post/archive-post.command';
import { CreateCommentCommand } from '../../shared/commands/comment/create-comment.command';
import { EditPostCommand } from '../../shared/commands/post/edit-post.command';
import { RestorePostCommand } from '../../shared/commands/post/restore-post.command';
import { ACTION_STATUS, POST_TYPES } from '../../shared/models/posts.enum';
import { Action, ActionStatus, Attachment, Comment, Post } from '../../shared/models/project.model';
import { EditCommentCommand } from '../../shared/commands/comment/edit-comment.command';
import { DeleteCommentCommand } from '../../shared/commands/comment/delete-comment.command';
import { ActionStatusSetCommand } from '../../shared/commands/post/action-status-set.command';
import { ProjectUser } from 'src/app/admin/shared/models/user.model';
import { UnassignUserCommand } from '../../shared/commands/post/unassign-user.command';
import { AssignUserCommand } from '../../shared/commands/post/assign-user.command';
import { EditDeadLineDateCommand } from '../../shared/commands/post/edit-deadline.command';
import { EditOpenedDateCommand } from '../../shared/commands/post/edit-openeddate.command';
import { SetPostDescriptionCommand } from '../../shared/commands/post/set-post-description.command';
import { ErrorCommandResponse } from 'src/app/core/crs/error-response.model';
import Autolinker from 'autolinker';
import * as Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import French from '@uppy/locales/lib/fr_FR';
import { CONFIG } from 'src/app/core/config/injectionConfig';
import { IWebSettings } from 'src/app/core/config/websettings.model';
import { RemoveAttachmentCommand } from '../../shared/commands/post/remove-attachment.command';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';

@Component( {
  selector: 'app-post-modal',
  templateUrl: './post-modal.component.html',
  styleUrls: ['./post-modal.component.less']
} )
export class PostModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild( 'modalComponent' ) modalComponent: GenericModalComponent;

  @Input() projectId: number;
  @Input() isVisible: boolean = false;

  get getPost(): Post | Action {
    return this._post;
  }
  @Input() set post( p: Post | Action ) {
    if ( typeof p !== 'undefined' ) {
      if ( 'actionStatusId' in p ) {
        this.isAction = true;
      }
      this._post = { ...p };
      this.refreshData();
    }
  };

  @Output() modalClosed = new EventEmitter<void>();

  @ViewChild( 'editInput' ) editInput: HTMLInputElement;
  @ViewChild( 'editCommentInput' ) editCommentInput: HTMLInputElement;
  @ViewChild( 'editDescription' ) editDescription: HTMLElement;

  private _uppy: Uppy.Uppy;
  private _uploadEndpoint: string;
  private _subscriptions: Array<Subscription>;
  private _post: Post | Action;
  public isAction: boolean;
  public postTypes = POST_TYPES;
  public commentContent: string;
  public showAddCommentBtn: boolean;
  public editingPostContent: boolean;
  public newPostContent: string;
  public currentUserIsViewer: boolean;
  public currentUserId: number;
  public editingComment: boolean;
  public newCommentContent: string;
  public actionTypes = ACTION_STATUS;
  public currentComment?: Comment;
  public editingPostStatus: boolean;
  public allActionStatus: Array<ActionStatus>;
  public selectedStatusId: number;
  public members: Array<ProjectUser>;
  public editingDeadLine: boolean;
  public editingOpened: boolean;
  public editingDescription: boolean;
  public newDescription: string;
  public commentSelectedFilter: string;
  public displayedComments: Array<Comment>;

  constructor (
    @Inject( CONFIG ) config: IWebSettings,
    private _authService: AuthService,
    private _crsService: CrsService,
    private _nzMessageService: NzMessageService,
    private _userService: UserService,
    private _projectApiService: ProjectApiService
  ) {
    this._uploadEndpoint = config.uploadAttachmentEndpoint;
    this.commentContent = '';
    this.newPostContent = '';
    this.showAddCommentBtn = false;
    this.editingPostContent = false;

    this._subscriptions = new Array<Subscription>();
    this.currentUserIsViewer = true;
    this.editingComment = false;
    this.newCommentContent = '';
    this.isAction = false;
    this.editingPostStatus = false;
    this.members = [];
    this.editingDeadLine = false;
    this.editingOpened = false;
    this.editingDescription = false;
    this.newDescription = '';
    this.commentSelectedFilter = 'all';
    this.displayedComments = [];
  }

  ngAfterViewInit(): void {
    this.startUppy();
  }

  ngOnInit(): void {
    this.getActionStatus();

    if ( this._post ) {
      this.newPostContent = this._post.title;
    }

    this._subscriptions.push(
      this._userService.userInfos$.pipe( filter( u => u !== undefined ) ).subscribe( u => {
        if ( u.isAdmin ||
          u.groups.filter( g => g.zoneId === this._post.projectId && !g.groupName.includes( 'Viewers' ) ).length > 0 ||
          u.groups.filter( g => g.groupName === 'Signature.Editors' || g.groupName === 'Signature.Admins' ).length > 0 ) {
          this.currentUserIsViewer = false;
        }
      } )
    );

    this.currentUserId = this._authService.authenticationInfo.user.userId;
  }

  getActionStatus(): void {
    this._projectApiService.getAllActionStatus().pipe( first() ).subscribe( as => {
      this.allActionStatus = [...as];
      if ( this.isAction ) {
        this.selectedStatusId = as.find( s => s.actionStatusId === ( this.getPost as Action ).actionStatusId ).actionStatusId;
      }
    } );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach( s => s.unsubscribe() );
  }

  closeModal(): void {
    this.cancelPostEdition();
    this.modalClosed.next();
  }

  formatDate( date: Date ): string {
    return dateToLocalFR( date.toString() );
  }

  commentPost(): void {
    const successMsg = 'Le commentaire a été ajouté avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la création du commentaire.';

    const command = new CreateCommentCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId,
      this.commentContent
    );
    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
        this.commentContent = '';
        this.showAddCommentBtn = false;
      } else {
        this._nzMessageService.error( errorMsg );
      }

      this.refreshData();
    } );
  }

  refreshData(): void {
    this._projectApiService
      .getPost( this._post.postId )
      .pipe( first() )
      .subscribe( p => {
        this._post = { ...p };
        this.displayedComments = [...p.comments];
      } );
  }

  startEditingPost(): void {
    this.editingPostContent = true;
    this.newPostContent = this._post.title;
    this.editInput.focus();
  }

  cancelPostEdition(): void {
    this.editingPostContent = false;
    this.newPostContent = this._post.title;
  }

  editPost(): void {
    const successMsg = 'Le contenu du post a été modifié avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la modification du post.';

    const command = new EditPostCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId,
      this.newPostContent,
      this._post.isImportant
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
      this.cancelPostEdition();
    } );
  }

  archivePost(): void {
    const successMsg = 'Le post a été archivé avec succès.';
    const errorMsg = 'Une erreur est survenue lors de l\'archivage du post.';

    const command = new ArchivePostCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
    } );
  }

  restorePost(): void {
    const successMsg = 'Le post a été restauré avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la restauration du post.';

    const command = new RestorePostCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
    } );
  }

  startEditingComment( c: Comment ): void {
    this.currentComment = { ...c };
    this.editingComment = true;
    this.newCommentContent = c.commentValue;
  }

  cancelCommentEdition(): void {
    this.editingComment = false;
    this.newCommentContent = this.currentComment.commentValue;
    this.currentComment = undefined;
  }

  editComment(): void {
    const successMsg = 'Le contenu du commentaire a été modifié avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la modification du commentaire.';

    const command = new EditCommentCommand(
      this._authService.authenticationInfo.user.userId,
      this.currentComment.commentId,
      this.newCommentContent
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
      this.cancelCommentEdition();
    } );
  }

  deleteComment( c ): void {
    const successMsg = 'Le commentaire a été supprimé avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la suppression du commentaire.';

    const command = new DeleteCommentCommand(
      this._authService.authenticationInfo.user.userId,
      c.commentId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
      this.cancelCommentEdition();
    } );
  }

  getStatusId(): number {
    if ( this.isAction ) {
      if ( ( this.getPost as Action ).actionStatusId ) {
        return ( this.getPost as Action ).actionStatusId;
      }
    }

    return 1;
  }

  getStatusText(): string {
    if ( this.isAction ) {
      return ( this.getPost as Action ).actionStatusText;
    }

    return 'En attente';
  }

  startEditingActionStatus(): void {
    this.editingPostStatus = true;
  }

  editActionStatus(): void {
    if ( this.isAction ) {
      const command = new ActionStatusSetCommand(
        this._authService.authenticationInfo.user.userId,
        this.getPost.postId,
        this.selectedStatusId
      );

      this._crsService.send<DefaultCommandResponse>( command ).then( res => {
        if ( res.success ) {
          this.editingPostStatus = false;
          this.refreshData();
        }
      } );
    }
  }

  isValidURL( string: string ): boolean {
    var res = string.match( /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g );
    return ( res !== null )
  }

  formatToPossibleLinks( text: string ): string {
    var string = Autolinker.link( text );
    return string.split( /^/gm ).join( '<br/>' );
  }

  getDateWithoutHours( d: Date ): string {
    return dateToLocalFRWithoutHours( d.toString() );
  }

  asAction(): Action {
    return this.getPost as Action;
  }

  getMemberTooltip( m: ProjectUser ): string {
    return `${m.firstName} ${m.lastName} (${m.userName})`;
  }

  fetchMembers(): void {
    if ( this.members.length === 0 ) {
      this._projectApiService.getProjectMembers( this.projectId ).pipe( first() ).subscribe( members => {
        this.members = [...members];
      } );
    }
  }

  assignUser( m: ProjectUser ): void {
    const command = new AssignUserCommand(
      this._authService.authenticationInfo.user.userId,
      this.getPost.projectId,
      this.getPost.postId,
      m.userId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( res => {
      if ( res.success ) {
        this._nzMessageService.success( `${m.userName} a bien été assigné au post.` );
      } else {
        this._nzMessageService.error( `${m.userName} n'a pas pu être assigné au post.` );
      }
      this.refreshData();
    } );
  }

  getMemberLabel( m: ProjectUser ): string {
    return `${m.firstName} ${m.lastName} (${m.userName})`;
  }

  getUserInitials( m: ProjectUser ): string {
    if ( m.firstName.length > 0 && m.lastName.length > 0 ) {
      return `${m.firstName.substring( 0, 1 ).toLocaleUpperCase()}${m.lastName.substring( 0, 1 ).toLocaleUpperCase()}`
    }

    return `${m.userName.substring( 0, 1 ).toLocaleUpperCase()}`;
  }

  shouldSuggestUser( m: ProjectUser ): boolean {
    return this.getPost.assignedUsers.findIndex( u => u.userId === m.userId ) === -1;
  }

  unassignMember( m: ProjectUser ): void {
    const command = new UnassignUserCommand(
      this._authService.authenticationInfo.user.userId,
      this.getPost.projectId,
      this.getPost.postId,
      m.userId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( res => {
      if ( res.success ) {
        this._nzMessageService.success( `${m.userName} a bien été retiré du post.` );
      } else {
        this._nzMessageService.error( `${m.userName} n'a pas pu être retiré du post.` );
      }
      this.refreshData();
    } );
  }

  editDeadLine( isOpen: boolean ): void {
    if ( !isOpen ) {
      this.editingDeadLine = false;
      const command = new EditDeadLineDateCommand(
        this._authService.authenticationInfo.user.userId,
        this.getPost.postId,
        this.asAction().deadLine
      );

      this._crsService.send<DefaultCommandResponse>( command ).then( res => {
        if ( res.success ) {
          this._nzMessageService.success( `La date butoir a bien été modifiée.` );
        } else {
          this._nzMessageService.error( `La date butoir n'a pas pu être modifiée.` );
        }

        this.refreshData();
      } );
    }
  }

  editOpened( isOpen: boolean ): void {
    if ( !isOpen ) {
      this.editingOpened = false;
      const command = new EditOpenedDateCommand(
        this._authService.authenticationInfo.user.userId,
        this.getPost.postId,
        this.getPost.openedDate
      );

      this._crsService.send<DefaultCommandResponse>( command ).then( res => {
        if ( res.success ) {
          this._nzMessageService.success( `La date effective a bien été modifiée.` );
        } else {
          this._nzMessageService.error( `La date effective n'a pas pu être modifiée.` );
        }

        this.refreshData();
      } );
    }
  }

  startEditingDescription(): void {
    this.newDescription = this.getPost.description;
    this.editingDescription = true;
  }

  cancelDescriptionEdition(): void {
    this.newDescription = this.getPost.description;
    this.editingDescription = false;
  }

  doEditDescription(): void {
    const command = new SetPostDescriptionCommand(
      this._authService.authenticationInfo.user.userId,
      this.getPost.postId,
      this.newDescription
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( res => {
      if ( res.success ) {
        this._nzMessageService.success( `La description du post a bien été modifiée.` );
      } else {
        this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
      }
      this.refreshData();
      this.cancelDescriptionEdition();
    } );
  }

  changeCommentFilter( type: string ): void {
    this.commentSelectedFilter = type;
    this.formatComments();
  }

  currentFilter( type: string ): boolean {
    return this.commentSelectedFilter === type;
  }

  formatComments(): void {
    switch ( this.commentSelectedFilter ) {
      case 'all':
        this.displayedComments = [...this.getPost.comments];
        break;
      case 'history':
        this.displayedComments = [...this.getPost.comments.filter( c => c.ownerId === 1 )];
        break;
      case 'users':
        this.displayedComments = [...this.getPost.comments.filter( c => c.ownerId !== 1 )];
        break;
      default:
        this.displayedComments = [...this.getPost.comments];
        break;
    }
  }

  startUpload(): void {
    this._uppy.upload();
  }

  startUppy(): void {

    const uppyOptions: Uppy.UppyOptions = {
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: 1
      },
      locale: French
    };

    this._uppy = new Uppy.Uppy( uppyOptions );
    this._uppy.use( Dashboard, {
      target: '.DashboardContainer',
      closeModalOnClickOutside: true,
      hideUploadButton: false,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
      showSelectedFiles: true,
      trigger: '.UppyModalOpenerBtn',
      inline: false
    } );

    this._uppy.use( XHRUpload, {
      endpoint: `${this._uploadEndpoint}/${this.getPost.postId}`,
      formData: true,
      method: 'post',
      /*
        We set timeout to 0 to prevent client side abortion due to lack of progress events
        This is due to large files taking a long time while being processed synchronously on this connection
        Reference: https://uppy.io/docs/xhr-upload/#timeout-30-1000
      */
      timeout: 0
    } );
    this._uppy.on( 'file-added', ( file ) => {
      this._uppy.setMeta( { actorId: this._authService.authenticationInfo.user.userId } );
    } );
    this._uppy.on( 'upload-success', () => this.handleFileImportSuccess() );
    this._uppy.on( 'upload-error', ( _file, _error, response ) => this.handleFileImportError( response ) );
  }

  handleFileImportSuccess(): void {
    this._nzMessageService.success( 'Le fichier a bien été téléchargé.' );
    this.refreshData();
  }

  handleFileImportError( resp: Uppy.ErrorResponse ): void {
    this._nzMessageService.error( resp.body );
  }

  downloadAttachment( a: Attachment ): void {
    this._projectApiService.getAttachmentFile( a.attachmentId ).pipe( first() ).subscribe( file => {
      const type = this.computeMimeType( a.attachmentName );
      const blob = new Blob( [file], { type: type } );
      const url = window.URL.createObjectURL( blob );
      const anchor = document.createElement( "a" );
      anchor.download = a.attachmentName;
      anchor.href = url;
      anchor.click();
    } );
  }

  computeMimeType( fileName: string ): string {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec( fileName );

    if ( ext !== undefined ) {
      switch ( ext.toString() ) {
        case 'xlsx':
          return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'pdf':
          return 'application/pdf';
        case 'png':
          return 'image/png';
        case 'pptx':
          return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        case 'zip':
        case '7zip':
          return 'application/zip';
        case 'jpg':
        case 'jpeg':
          return 'image/jpeg';
        case 'csv':
          return 'text/csv';
        case 'docx':
          return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'doc':
          return 'application/msword';
        case 'json':
          return 'application/json';
        default:
          break;
      }
    }

    return '';
  }

  removeAttachment( a: Attachment ): void {
    const command = new RemoveAttachmentCommand(
      this._authService.authenticationInfo.user.userId,
      a.attachmentId,
      a.postId
    );

    this.modalComponent.openConfirmModal(
      `Êtes-vous sûr(e) de vouloir supprimer la pièce jointe : ${a.attachmentName}`,
      'La pièce jointe a bien été supprimée.',
      '',
      command
    );
  }
}
