import { Command } from "@signature/crs-client";

@Command( 'RemoveAttachment' )
export class RemoveAttachmentCommand {
    constructor (
        public actorId: number,
        public attachmentId: number,
        public postId: number
    ) {
    }
}