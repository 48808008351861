import { Command } from "@signature/crs-client";

@Command( 'SetPostDescription' )
export class SetPostDescriptionCommand {
    constructor (
        public actorId: number,
        public postId: number,
        public description: string
    ) {
    }
}