<ng-container *ngIf="getPost">
    <nz-modal 
        [nzWidth]="900"
        [(nzVisible)]="isVisible" 
        [nzContent]="modalContent"
        [nzFooter]="modalFooter"
        (nzOnCancel)="closeModal()"
        [nzStyle]="{ 'max-height': '70vh', 'top': '50px' }"
        [nzBodyStyle]="{ 'height': '70vh', 'display': 'flex', 'flex-direction': 'column' }">
        <div *nzModalTitle class="modal-title">
            <div class="title-container">
                <div class="post-content">
                    <div class="content-container" *ngIf="!editingPostContent; else contentEdition" (click)="startEditingPost()">
                        <span *ngIf="!currentUserIsViewer">
                            <ng-container *ngIf="isValidURL(getPost.title); else normalContent">
                                <a [href]="getPost.title" target="_blank">{{ getPost.title }}</a>
                            </ng-container>
                            <ng-template #normalContent>
                                <span>{{ getPost.title }}</span>
                            </ng-template>
                        </span>
                    </div>
                    <ng-template #contentEdition>
                        <input #editInput nz-input [(ngModel)]="newPostContent" (blur)="newPostContent !== getPost.title ? editPost() : cancelPostEdition()" />
                    </ng-template>
                </div>
                <nz-tag *ngIf="getPost.isPrivate" nzColor="error">
                    <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
                </nz-tag>
            </div>
            <button 
                class="archive-btn" 
                *ngIf="!getPost.isArchived && !currentUserIsViewer; else restore" 
                nz-button 
                nzType="primary" 
                (click)="archivePost()"
                nz-tooltip nzTooltipTitle="Archiver">
                <i nz-icon nzType="delete" nzTheme="outline"></i>
            </button>
            <ng-template #restore>
                <button *ngIf="!currentUserIsViewer" nz-button nzType="primary" (click)="restorePost()" nz-tooltip nzTooltipTitle="Restaurer">
                    <i nz-icon nzType="undo" nzTheme="outline"></i>
                </button>
            </ng-template>
        </div>
        <ng-template #modalContent>
            <div class="infos">
                <span># {{ getPost.postId }}</span>
                <ng-container *ngIf="formatDate( getPost.lastEditionDate ) !== '-'">
                    <span class="owner">
                        Modifié le {{ formatDate( getPost.lastEditionDate ) }} par {{ getPost.editorFirstName }} {{ getPost.editorLastName }} ({{ getPost.editorUserName }})
                    </span>
                </ng-container>
                <div [ngSwitch]="getPost.postTypeId">
                    <ng-container *ngSwitchCase="postTypes.INFORMATION">                        
                        <nz-tag [nzColor]="'gold'">
                            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            {{ getPost.postType }}
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="postTypes.DECISION">                        
                        <nz-tag [nzColor]="'volcano'">
                            <i nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
                            {{ getPost.postType }}
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="postTypes.ACTION">                        
                        <nz-tag [nzColor]="'blue'">
                            <i nz-icon nzType="issues-close" nzTheme="outline"></i>
                            {{ getPost.postType }}
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="getPost.isImportant">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="warning" nzTheme="outline"></i>
                            Important
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="getPost.isArchived">
                        <nz-tag nzColor="default">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                            Archivé
                        </nz-tag>
                    </ng-container>
                </div>
            </div>
            <div class="dates">
                <div class="owner">
                    Posté par : {{ getPost.ownerFirstName }} {{ getPost.ownerLastName }} ({{ getPost.ownerUserName }}) <br/>
                    Le {{ formatDate( getPost.creationDate ) }}
                </div>
                <div class="owner deadline" *ngIf="getPost.postTypeId === postTypes.ACTION">
                    <ng-container *ngIf="editingDeadLine; else deadLineDisplay">
                        <nz-date-picker 
                            [(ngModel)]="asAction().deadLine" 
                            nzFormat="dd/MM/yyyy" 
                            [nzAutoFocus]="true"
                            (nzOnOpenChange)="editDeadLine($event)"
                        ></nz-date-picker>
                    </ng-container>
                    <ng-template #deadLineDisplay>
                        <span (click)="editingDeadLine = true">
                            Date cible : {{ getDateWithoutHours( asAction().deadLine ) }}
                        </span>
                    </ng-template>
                </div>
                <div class="owner deadline">
                    <ng-container *ngIf="editingOpened; else openedDisplay">
                        <nz-date-picker 
                            [(ngModel)]="getPost.openedDate" 
                            nzFormat="dd/MM/yyyy" 
                            [nzAutoFocus]="true"
                            (nzOnOpenChange)="editOpened($event)"
                        ></nz-date-picker>
                    </ng-container>
                    <ng-template #openedDisplay>                        
                        <span (click)="editingOpened = true">
                            Date effective : {{ getDateWithoutHours( getPost.openedDate ) }}
                        </span>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="isAction && !editingPostStatus" [ngSwitch]="getStatusId()" class="action-status">
                <ng-container *ngSwitchCase="actionTypes.PENDING">
                    <nz-tag nzColor="default">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <ng-container *ngSwitchCase="actionTypes.ONGOING">
                    <nz-tag [nzColor]="'gold'">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <ng-container *ngSwitchCase="actionTypes.COMPLETED">
                    <nz-tag [nzColor]="'blue'">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <ng-container *ngSwitchCase="actionTypes.CANCELED">
                    <nz-tag nzColor="error">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <a nz-button nzType="link" *ngIf="!currentUserIsViewer && !editingPostStatus;" (click)="startEditingActionStatus()">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                </a>
            </div>
            <div class="action-status select" *ngIf="editingPostStatus">
                <nz-select [(ngModel)]="selectedStatusId">
                    <nz-option *ngFor="let s of allActionStatus" [nzLabel]="s.actionStatusText" [nzValue]="s.actionStatusId"></nz-option>
                </nz-select>
                <button nz-button nzType="primary" (click)="editActionStatus()">
                    <i nz-icon nzType="check" nzTheme="outline"></i>
                </button>
                <a nz-button nzType="link" (click)="editingPostStatus = false">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </a>
            </div>
            <div class="assigns-and-attachments">
                <div class="assigned-users">
                    <span class="label">Assigné à : </span>
                    <ng-container *ngFor="let m of getPost.assignedUsers">
                        <ng-container *ngIf="!currentUserIsViewer; else viewer">
                            <div 
                                class="member"
                                nzTooltipPlacement="top" 
                                nz-tooltip 
                                [nzTooltipTitle]="tooltipTemplate"
                                [nzTooltipTitleContext]="{ $implicit: m }"
                                (click)="unassignMember( m )">
                                <span class="initial">{{ getUserInitials( m ) }}</span>
                                <i nz-icon nzType="close" nzTheme="outline"></i>
                            </div>
                            <ng-template #tooltipTemplate let-user>
                                {{ getMemberTooltip( user ) }}
                            </ng-template>
                        </ng-container>
                        <ng-template #viewer>
                            <div 
                                class="member"
                                nzTooltipPlacement="top" 
                                nz-tooltip 
                                [nzTooltipTitle]="tooltipTemplate"
                                [nzTooltipTitleContext]="{ $implicit: m }">
                                <span class="initial">{{ getUserInitials( m ) }}</span>
                            </div>
                            <ng-template #tooltipTemplate let-user>
                                {{ getMemberTooltip( user ) }}
                            </ng-template>
                        </ng-template>
                    </ng-container>
                    <a class="assign-icon" nz-button nzType="link" 
                        nz-dropdown nzTrigger="click" [nzDropdownMenu]="userMenu" (click)="fetchMembers()"
                        nz-tooltip="Assigner le post">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                    </a>
                    <nz-dropdown-menu #userMenu="nzDropdownMenu">
                        <ul nz-menu>
                            <ng-container *ngFor="let m of members">
                                <li *ngIf="shouldSuggestUser( m )" nz-menu-item (click)="assignUser( m )">
                                    <span>{{ getMemberLabel( m ) }}</span>
                                </li>
                            </ng-container>
                        </ul>
                    </nz-dropdown-menu>
                </div>
                <div class="attachments">
                    <div class="container" *ngFor="let a of getPost.attachments">
                        <nz-tag (dblclick)="downloadAttachment( a )">
                            <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                            <span class="attachment-name" (click)="downloadAttachment( a )">{{ a.attachmentName }}</span>
                        </nz-tag>
                        <div class="remove-button">
                            <a nz-button nzType="link" nz-tooltip="Supprimer la pièce jointe">
                                <i nz-icon nzType="close" nzTheme="outline" (click)="removeAttachment( a )"></i>
                            </a>
                        </div>
                    </div>
                    <div class="add-attachment">
                        <a nz-button nzType="link" class="UppyModalOpenerBtn" nz-tooltip="Ajouter une pièce jointe">
                            <i nz-icon nzType="file-add" nzTheme="outline"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="DashboardContainer"></div>
            <nz-divider></nz-divider>
            <div class="post-description" (click)="startEditingDescription()">                
                <ng-container *ngIf="!editingDescription; else descriptionEdition">
                    <ng-container *ngIf="getPost.description.length === 0; else description">
                        <span>Cliquez pour ajouter une description...</span>
                    </ng-container>
                    <ng-template #description>
                        <span [innerHTML]="formatToPossibleLinks( getPost.description )"></span>
                    </ng-template>
                </ng-container>
                <ng-template #descriptionEdition>
                    <nz-textarea-count [nzMaxCharacterCount]="400">
                        <textarea 
                            #editDescription
                            class="comment-input" 
                            rows="4" 
                            [(ngModel)]="newDescription" 
                            nz-input 
                            (blur)="newDescription !== getPost.description ? doEditDescription() : cancelDescriptionEdition()">
                        </textarea>
                    </nz-textarea-count>
                </ng-template>
            </div>
            <nz-divider [nzText]="text">
                <ng-template #text>
                    <i nz-icon nzType="filter" nzTheme="outline" class="comment-filter" nz-dropdown nzTrigger="click" [nzDropdownMenu]="filterMenu"></i>
                </ng-template>
                <nz-dropdown-menu #filterMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li class="comment-filter-menu" nz-menu-item [nzSelected]="currentFilter( 'all' )" (click)="changeCommentFilter( 'all' )">
                            <i nz-icon nzType="check" nzTheme="outline"></i> Tous les commentaires
                        </li>
                        <li class="comment-filter-menu" nz-menu-item [nzSelected]="currentFilter( 'history' )" (click)="changeCommentFilter( 'history' )">
                            <i nz-icon nzType="history" nzTheme="outline"></i> Historique
                        </li>
                        <li class="comment-filter-menu" nz-menu-item [nzSelected]="currentFilter( 'users' )" (click)="changeCommentFilter( 'users' )">
                            <i nz-icon nzType="user" nzTheme="outline"></i> Commentaires utilisateurs
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </nz-divider>
            <ng-container>
                <nz-list class="comment-list">
                    <nz-list-item *ngFor="let c of displayedComments;" class="comment">
                        <div class="content">
                            <ng-container *ngIf="!currentComment || currentComment.commentId !== c.commentId; else commentEdition">
                            <span [innerHTML]="formatToPossibleLinks( c.commentValue )"></span>
                            <div class="buttons" *ngIf="currentComment !== c && c.ownerId > 1">
                                <a nz-button nzType="link" (click)="startEditingComment( c )" nz-tooltip nzTooltipTitle="Modifier">
                                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                                </a>
                                <a *ngIf="!currentUserIsViewer || c.ownerId === currentUserId" nz-button nzType="link" (click)="deleteComment( c )" nz-tooltip nzTooltipTitle="Supprimer">
                                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                                </a>
                            </div>
                            </ng-container>
                            <ng-template #commentEdition>
                                <input #editCommentInput nz-input 
                                    [(ngModel)]="newCommentContent" (blur)="newCommentContent !== currentComment.commentValue ?? cancelCommentEdition()" />
                                <button nz-button nzType="primary" 
                                    (click)="editComment()" 
                                    nz-tooltip="Valider"
                                    [disabled]="newCommentContent !== currentComment.commentValue ? false : true">
                                    <i nz-icon nzType="check" nzTheme="outline"></i>
                                </button>
                                <a class="cancel" nzType="link" nz-button (click)="cancelCommentEdition()" nz-tooltip="Annuler">
                                    <i nz-icon nzType="close" nzTheme="outline"></i>                        
                                </a>
                            </ng-template>
                        </div>
                        <div class="infos">
                            <span>{{ c.ownerFirstName }} {{ c.ownerLastName }} ({{ c.ownerUserName }}) le {{ formatDate( c.creationDate ) }}</span>
                            <span *ngIf="formatDate(c.editionDate) !== '-'">Modifié le : {{ formatDate( c.editionDate ) }}</span>
                        </div>
                    </nz-list-item>                    
                </nz-list>
            </ng-container>
        </ng-template>
        <ng-template #modalFooter>
            <nz-textarea-count [nzMaxCharacterCount]="400">
                <textarea 
                    class="comment-input" 
                    rows="4" 
                    [(ngModel)]="commentContent" 
                    nz-input 
                    placeholder="Ajouter un commentaire" 
                    (focus)="showAddCommentBtn = true"
                    (blur)="commentContent.length === 0 ? showAddCommentBtn = false : showAddCommentBtn = true">
                </textarea>
            </nz-textarea-count>
            <div class="comment-btn">
                <button nz-button nzType="primary" (click)="commentPost()" [disabled]="commentContent.length === 0">Commenter</button>
            </div>
        </ng-template>
    </nz-modal>
</ng-container>

<app-generic-modal
    #modalComponent
    (actionExecuted)="refreshData()">
</app-generic-modal>