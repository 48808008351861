<nz-drawer
    [nzClosable]="false"
    [nzVisible]="isVisible"
    nzPlacement="right"
    nzTitle="Vos notifications"
    [nzWidth]="600"
    (nzOnClose)="close()"
>
    <div class="notif-container" *nzDrawerContent>
        <ng-container *ngIf="notifs.length > 0; else empty">
            <a class="delete-all-btn" nz-button nzType="text" (click)="deleteAllNotifs()">Tout supprimer</a>
            <div class="notif" *ngFor="let item of notifs">
                <div class="content">
                    <span *ngIf="!item.hasRead" class="dot"></span>
                    <span (dblclick)="goTo( item )">{{ item.content }}</span>
                    <a nDanger nz-button nzType="link" (click)="deleteNotif( item.notificationId )" nz-tooltip nzTooltipTitle="Supprimer">
                        <i nz-icon nzType="close" nzTheme="outline"></i>
                    </a>
                </div>
    
                <nz-divider></nz-divider>
            </div>
        </ng-container>
        <ng-template #empty>
            <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="contentTpl">
                <ng-template #contentTpl>
                    Aucune notification.
                </ng-template>
            </nz-empty>
        </ng-template>
    </div>
</nz-drawer>