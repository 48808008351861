import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAggregate } from 'src/app/admin/shared/models/user.model';
import { Notification } from 'src/app/shared/models/notification.model';
import { CONFIG } from '../config/injectionConfig';
import { IWebSettings } from '../config/websettings.model';

@Injectable( {
  providedIn: 'root'
} )
export class UserApiService {

  private _baseUrl: string;

  constructor (
    private _httpClient: HttpClient,
    @Inject( CONFIG ) config: IWebSettings
  ) {
    this._baseUrl = `${config.apiRoot}/user`;
  }

  public getCurrentUserInfos(): Observable<UserAggregate> {
    return this._httpClient.get<UserAggregate>( `${this._baseUrl}/currentUser` );
  }

  public getUsersNotifications(): Observable<Array<Notification>> {
    return this._httpClient.get<Array<Notification>>( `${this._baseUrl}/notifications` );
  }
}
