import { Command } from "@signature/crs-client";

@Command( 'CreatePost' )
export class CreatePostCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public postTypeId: Number,
        public postContent: string,
        public description: string,
        public isImportant: boolean = false,
        public isPrivate: boolean = false,
        public openedDate: Date,
        public assignedUsers: Array<number>,
        public deadLine?: Date
    ) {
    }
}